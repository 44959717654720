
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '../../types/common'

@Component({
  name: 'DetailList',
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class DetailList extends Vue {
  private info = []

  get eventsId () {
    return this.$route.params.id as string || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.engineering.selectEngineeringEventByEventsId, { eventsId: this.eventsId }).then(res => {
      if (res) {
        this.info = res
      }
    })
  }
}
